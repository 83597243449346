export const sections = [
    {
        title: "Home",
        url: "/#",
    },
    {
        title: "University",
        url: "/#university",
    },
    {
        title: "City",
        url: "/#city",
    },
    {
        title: "Opinion",
        url: "/#opinion",
    },
    {
        title: "Photo",
        url: "/#photo",
    },
    {
        title: "Credits",
        url: "/#credits",
    }
];